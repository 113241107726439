/***********************************************************************************************************************
* BEFORE USING CUSTOM SCSS/CSS PLEASE TRY AND LEVERAGE THE UIKIT AS MUCH AS POSSIBLE                                   *
* http://availity.github.io/availity-uikit/v3/components/                                                              *
*                                                                                                                      *
* EVERY TIME A CUSTOM STYLE IS CREATED AN ANGEL LOSES ITS WINGS                                                        *
***********************************************************************************************************************/
$availity-font-path: '~availity-uikit/fonts';
@import '~availity-uikit/scss/_bootstrap';

body {
  background: #f8f8f8;
}

.folder-item:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.folder-item:focus {
  background-color: none;
}

.admin-link {
  padding: 0.75rem;
}

.action-card:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.cursor-pointer {
  cursor: pointer;
}

.login-card {
  margin: auto;
  max-width: 350px;
  border-radius: 8px;
}

.login-card-header {
  background-color: #4d4f53;
  padding: 1em;
  border-radius: 8px 8px 0px 0px;
}

.external-toggle {
  position: fixed;
  bottom: 0;
  left: 96px;
  bottom: 16px;
}

.mbx-user-list dd {
  margin-bottom: -5px;
}
